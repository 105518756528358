import { PropsWithChildren } from "react";
import clsx from "clsx";
import styled from "styled-components";

import { FormStates, StateValue } from "@/panels/signup/FormStateMachine";
import { useSignupStore } from "@/reducers";
import classes from "./stepper.module.css";

const isAccountStep = (state: StateValue) => {
  return [
    //FormStates.KYC,
    FormStates.EMAIL,
    FormStates.PHONE,
    FormStates.VERIFY_PHONE,
    FormStates.VERIFY_EMAIL,
    FormStates.DISCLOSURE,
  ].includes(state);
};

const isVerificationStep = (state: StateValue) => {
  return [FormStates.KYC_GENERATE_QUESTIONS, FormStates.PASSWORD].includes(
    state
  );
};

const isPersonalStep = (state: StateValue) => {
  return [FormStates.PERSONAL_INFO].includes(state);
};

const isOccupationStep = (state: StateValue) => {
  return [FormStates.OCCUPATION].includes(state);
};

const List = styled.ol`
  display: flex;
  flex-direction: column;
`;

const Item = ({
  isActive,
  children,
  ariaLabel,
}: PropsWithChildren<{ isActive: boolean; ariaLabel?: string }>) => {
  return (
    <li
      aria-label={ariaLabel}
      className={clsx(
        classes.item,
        "after:border-2 before:bg-pebble dark:before:bg-rock",
        {
          ["text-clementine dark:text-sun after:bg-clementine dark:after:bg-sun after:border-clementine dark:after:border-sun"]:
            isActive,
          "after:border-pebble dark:after:border-rock after:bg-pebble dark:after:bg-rock text-pebble dark:text-rock":
            !isActive,
        }
      )}
    >
      {children}
    </li>
  );
};

const Step = ({
  isActive,
  children,
}: PropsWithChildren<{ isActive: boolean }>) => {
  return (
    <li
      className={clsx(classes.step, {
        "text-clementine dark:text-sun": isActive,
        "text-pebble dark:text-rock": !isActive,
      })}
    >
      {children}
    </li>
  );
};

export const Stepper = () => {
  const formState = useSignupStore.use.formState();

  if (formState === FormStates.EMAIL) {
    return (
      <div className="hidden md:block md:-mt-1">
        <div className="dark:text-sun text-clementine text-md md:text-[48px] leading-tight">
          Sign up to Paytrie,
        </div>
        <div className="text-md md:text-[48px] leading-tight">
          Canada&apos;s home for stablecoins
        </div>
      </div>
    );
  }

  return (
    <List className="mt-1 px-3">
      <Item isActive={isAccountStep(formState)} ariaLabel="Account">
        <h3 className={classes.stepTitle}>Account</h3>
        {isAccountStep(formState) && (
          <ul>
            <Step
              isActive={[FormStates.VERIFY_EMAIL, FormStates.EMAIL].includes(
                formState
              )}
            >
              Email
            </Step>
            <Step
              isActive={[FormStates.PHONE, FormStates.VERIFY_PHONE].includes(
                formState
              )}
            >
              Phone number
            </Step>
            <Step isActive={[FormStates.DISCLOSURE].includes(formState)}>
              Disclosure
            </Step>
          </ul>
        )}
      </Item>
      <Item isActive={isPersonalStep(formState)}>
        <h3 className={classes.stepTitle}>Identity</h3>
      </Item>
      <Item isActive={isOccupationStep(formState)}>
        <h3 className={classes.stepTitle}>Occupation</h3>
      </Item>
      <Item isActive={isVerificationStep(formState)}>
        <h3 className={classes.stepTitle}>Verification</h3>
        {isVerificationStep(formState) &&
          formState === FormStates.KYC_GENERATE_QUESTIONS && <div />}
      </Item>
    </List>
  );
};
